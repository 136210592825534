import {
  columnsNames,
  dbEditKeys,
  discountKeys,
  sampleInfoKeys,
} from "./constant";

import SelectDropDown from "../Components/SelectDropdown/SelectDropDown";
import styles from "../sample_strategy.module.css";
import {
  calculateTotalDiscountOnTable,
  calculateTotalSampleCost,
} from "./fieldsCalculationsFormulas";
import CustomInputField from "../Components/CustomInputField/CustomInputField";
import CustomTextArea from "../Components/CustomTextArea/CustomTextArea";

export const formatCurrencyInput = (value) => {
  // Remove any non-numeric characters except for the decimal point
  const cleanedValue = value.replace(/[^0-9]/g, "");
  const maxDigits = 8;
  if (cleanedValue.length > maxDigits) {
    return cleanedValue.slice(0, maxDigits);
  }

  // Split the cleaned value into integer and decimal parts
  let integerPart = cleanedValue.slice(0, 5); // First 5 digits
  let decimalPart = cleanedValue.slice(5, 7); // Next 2 digits
  let newValue = integerPart;
  if (decimalPart) {
    newValue = integerPart + "." + decimalPart;
  }

  return newValue;
};

export const formatNumber = (value) => {
  // Remove currency symbol if it exists and parse the value as a float
  const number = parseFloat(value.replace(/[^\d.-]/g, ""));

  // Check if the value is exactly "00.00"
  if (number === 0) {
    return `${value}`; // Return the original value with $ if it's "00.00"
  }

  // If the number has a decimal part, format it with commas for the integer part
  if (number % 1 !== 0) {
    const [integerPart, decimalPart] = number.toFixed(2).split("."); // Ensure 2 decimal places

    // Format the integer part with commas
    const formattedInteger = parseInt(integerPart).toLocaleString("en-IN");

    // Return the formatted integer part with the decimal part intact and prepend $
    return `$${formattedInteger}.${decimalPart}`;
  }

  // Format the integer (no decimal part) with commas and prepend $
  return `$${number.toLocaleString("en-IN")}`;
};

// export const compareSampleReports = (oldReport, newReport) => {
//   const reportMap = new Map(oldReport.map(item => [item.id, item]));
//   newReport.forEach(newItem => {
//     if (!reportMap.has(newItem.id)) {
//       reportMap.set(newItem.id, newItem);
//     }
//   });
//   return Array.from(reportMap.values());
// }

export const compareSampleReports = (oldReport, newReport) => {
  const reportMap = new Map(oldReport.map((item) => [item.id, item]));

  newReport.forEach((newItem) => {
    if (reportMap.has(newItem.id)) {
      // Update the observation name if it has changed
      const existingItem = reportMap.get(newItem.id);
      if (existingItem.observation.name !== newItem.observation.name) {
        existingItem.observation.name = newItem.observation.name;
      }
    } else {
      reportMap.set(newItem.id, newItem);
    }
  });

  // Remove items from reportMap that are not in newReport
  const newReportIds = new Set(newReport.map((item) => item.id));
  Array.from(reportMap.keys()).forEach((id) => {
    if (!newReportIds.has(id)) {
      reportMap.delete(id);
    }
  });

  // Group by room_name.name
  const groupedReports = Array.from(reportMap.values()).reduce(
    (groups, item) => {
      const roomName = item.room_name.name;
      if (!groups[roomName]) {
        groups[roomName] = [];
      }
      groups[roomName].push(item);
      return groups;
    },
    {}
  );

  // Sort items by observation.name according to the order in oldReport
  const sortedReport = Object.keys(groupedReports).reduce(
    (result, roomName) => {
      const roomItems = groupedReports[roomName];
      const sortedRoomItems = roomItems.sort((a, b) => {
        const oldReportIndexA = oldReport.findIndex(
          (item) => item.observation.name === a.observation.name
        );
        const oldReportIndexB = oldReport.findIndex(
          (item) => item.observation.name === b.observation.name
        );

        return (
          (oldReportIndexA === -1 ? Infinity : oldReportIndexA) -
          (oldReportIndexB === -1 ? Infinity : oldReportIndexB)
        );
      });
      result.push(...sortedRoomItems);
      return result;
    },
    []
  );

  return sortedReport;
};

export const CustomTestDropDown = (
  options,
  onUpdate,
  props,
  rowIndex,
  data
) => {
  return (
    <SelectDropDown
      id={rowIndex}
      placeholder="Select .."
      options={options}
      handleOnChange={(e) => {
        const updatedTest = {
          id: e.value,
          name: e.label,
        };
        onUpdate(updatedTest);
      }}
      searchValue={options.find(
        (option) => Number(option.value) === Number(props.defaultValue.id)
      )}
      isSearchable={false}
      isClearable={false}
    />
  );
};

export const CustomDiscountDropDown = (
  options,
  onUpdate,
  props,
  rowIndex,
  data
) => {
  return (
    <SelectDropDown
      placeholder="Select .."
      options={options}
      handleOnChange={(e) => {
        const updatedDiscount = {
          id: e.value,
          name: e.label,
        };
        onUpdate(updatedDiscount);
      }}
      searchValue={options.find(
        (option) => Number(option.value) === Number(props.defaultValue.id)
      )}
      isSearchable={false}
      isClearable={false}
    />
  );
};

export const CustomReasonDropDown = (
  options,
  onUpdate,
  props,
  rowIndex,
  data
) => {
  return (
    <SelectDropDown
      placeholder="Select .."
      options={options}
      handleOnChange={(e) => {
        const updatedReason = {
          id: e.value,
          name: e.label,
        };
        onUpdate(updatedReason);
      }}
      searchValue={options.find(
        (option) => Number(option.value) === Number(props.defaultValue.id)
      )}
      isSearchable={false}
      isClearable={false}
    />
  );
};

const CustomHeader = (column, colIndex, handleHiddenColumn) => {
  return (
    <div className={styles.hide_btn_container}>
      <div>{column.text}</div>
      <div onClick={handleHiddenColumn}>
        <img
          className={styles.icon}
          src={require("assets/icons/column_icon.png").default}
          alt="icon"
        />
      </div>
    </div>
  );
};

const customCellForFlagFormatter = (cell, row, handleFlagSelection) => {
  return (
    <img
      className={styles.icon}
      src={
        row[columnsNames.client_approval.value]
          ? require("assets/icons/flagchecked.png").default
          : require("assets/icons/flagunchecked.png").default
      }
      alt={row[columnsNames.client_approval.value] ? "Active" : "Inactive"}
      onClick={() => handleFlagSelection(row)}
    />
  );
};

const addRowNumber = (data) => {
  data?.sample_strategy_report.forEach((row, index) => {
    row.row_number = index + 1;
  });
};

const handleSampleLocationAndLabCode = (data) => {
  data?.sample_strategy_report.forEach((row, index) => {
    let isSampleLocationPresent = "";
    let isLabCodePresent = "";
    if (row.sample_info.length > 0) {
      row.sample_info.forEach((info) => {
        if (info.name === sampleInfoKeys.SAMPLE_LOCATION.name) {
          isSampleLocationPresent = true;
        } else if (info.name === sampleInfoKeys.LAB_CODE.name) {
          isLabCodePresent = true;
        }
      });
      if (!isSampleLocationPresent) {
        row.sample_info.unshift(sampleInfoKeys.SAMPLE_LOCATION);
      }
      if (!isLabCodePresent) {
        row.sample_info.push(sampleInfoKeys.LAB_CODE);
      }
    } else {
      row.sample_info.push(sampleInfoKeys.SAMPLE_LOCATION);
      row.sample_info.push(sampleInfoKeys.LAB_CODE);
    }
  });
};

export const markRowsAndColFalse = (data) => {
  data?.sample_strategy_report.forEach((row) => {
    row[dbEditKeys.rowEdit] = false;
    if (row.room_name) {
      row.room_name[dbEditKeys.colEdit] = false;
    }
    if (row.observation) {
      row.observation[dbEditKeys.colEdit] = false;
    }
    if (row.test) {
      row.test[dbEditKeys.colEdit] = false;
    }
    if (row.sample_info?.[0]) {
      row.sample_info[0][dbEditKeys.colEdit] = false;
    }
    if (row.sample_info?.[1]) {
      row.sample_info[1][dbEditKeys.colEdit] = false;
    }
    if (row.test_sample) {
      row.test_sample[dbEditKeys.colEdit] = false;
    }
  });
};

export const markRowsAndColTrue = (data) => {
  data?.sample_strategy_report.forEach((row) => {
    row[dbEditKeys.rowEdit] = true;
    row.room_name[dbEditKeys.colEdit] = true;
    row.observation[dbEditKeys.colEdit] = true;
    row.test[dbEditKeys.colEdit] = true;
    row.sample_info[0][dbEditKeys.colEdit] = true;
    row.sample_info[1][dbEditKeys.colEdit] = true;
    row.test_sample[dbEditKeys.colEdit] = true;
  });
};

export const handleEmptyColumns = (data) => {
  /* add row number */
  addRowNumber(data);
  /* add row number */

  /* handle Sample Location and Lab code */
  handleSampleLocationAndLabCode(data);
  /* handle Sample Location and Lab code */

  /* mark all rows and col false */
  markRowsAndColFalse(data);
  /* mark all rows and col false */
};

export const getNestedValue = (obj, path) => {
  const keys = path.split(".");
  return keys.reduce(
    (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
    obj
  );
};

export const setNestedValue = (obj, path, value) => {
  const keys = path.split(".");
  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = value;
    }
    return acc[key] !== undefined ? acc[key] : (acc[key] = {});
  }, obj);
};

export const setColumnEditTrue = (key, row) => {
  if (key === columnsNames.room.value) {
    row.room_name[dbEditKeys.colEdit] = true;
  }
  if (key === columnsNames.observation.value) {
    row.observation[dbEditKeys.colEdit] = true;
  }
  if (key === columnsNames.sample_type.value) {
    row.test[dbEditKeys.colEdit] = true;
  }
  if (key === columnsNames.sample_location.value) {
    row.sample_info[0][dbEditKeys.colEdit] = true;
  }
  if (key === columnsNames.lab_code.value) {
    row.sample_info[1][dbEditKeys.colEdit] = true;
  }
  if (key === columnsNames.sample_cost.value) {
    row.test_sample[dbEditKeys.colEdit] = true;
  }
};

const handleRoomUpdateLogic = (key, row, data) => {
  let updatedRoom = "";
  updatedRoom = row?.room_name;

  data.forEach((li) => {
    if (li.room_name?.id === updatedRoom.id) {
      li.room_name = JSON.parse(JSON.stringify(updatedRoom));
      li.room_name[dbEditKeys.colEdit] = true;
      li[dbEditKeys.rowEdit] = true;
    }
  });
};

const handleObservationUpdateLogic = (key, row, data) => {
  let updatedObservation = "";
  updatedObservation = row?.observation;

  data.forEach((li) => {
    if (li.observation_id === row.observation_id) {
      li.observation = JSON.parse(JSON.stringify(updatedObservation));
      li.observation[dbEditKeys.colEdit] = true;
      li[dbEditKeys.rowEdit] = true;
    }
  });
};

const handleTestUpdateLogic = (key, row, data, testList) => {
  const selectedTest = row?.test;
  const selectedTestSampleCost = testList.find(
    (test) => Number(test.id) === Number(selectedTest.id)
  );
  if (
    selectedTestSampleCost &&
    selectedTestSampleCost?.test_samples.length > 0
  ) {
    const cost = selectedTestSampleCost?.test_samples[0]?.cost;
    const sample_id = selectedTestSampleCost?.test_samples[0]?.id;
    data.forEach((li) => {
      if (li?.id === row.id) {
        li.test.sample_id = sample_id;
        li.test_sample.cost = cost;
        li.test_sample[dbEditKeys.colEdit] = true;
        li[dbEditKeys.rowEdit] = true;
      }
    });
  }
};

export const handleSampleCostUpdateLogic = (data, summary) => {
  const totalSampleCost = calculateTotalSampleCost(data);
  summary.total_sample_cost = totalSampleCost.toFixed(2);
};

export const handleDiscountUpdateLogic = (data, summary, discount) => {
  const sampleDiscount = calculateTotalDiscountOnTable(data);
  discount[discountKeys.SAMPLE_DISCOUNT] = sampleDiscount.toFixed(2);
  const totalDiscount =
    Number(sampleDiscount) + Number(discount[discountKeys.OTHER_DISCOUNT]);
  summary.total_discount = totalDiscount.toFixed(2);
};

export const handleCustomValidationAndUpdateColumnOnDemand = ({
  key,
  row,
  sample_strategy_report,
  testList,
  summary,
  discount,
}) => {
  switch (key) {
    case columnsNames.room.value:
      handleRoomUpdateLogic(key, row, sample_strategy_report);
      return;
    case columnsNames.observation.value:
      handleObservationUpdateLogic(key, row, sample_strategy_report);
      return;
    case columnsNames.sample_type.value:
      handleTestUpdateLogic(key, row, sample_strategy_report, testList);
      handleSampleCostUpdateLogic(sample_strategy_report, summary);
      handleDiscountUpdateLogic(sample_strategy_report, summary, discount);
      return;
    case columnsNames.sample_cost.value:
      handleSampleCostUpdateLogic(sample_strategy_report, summary);
      handleDiscountUpdateLogic(sample_strategy_report, summary, discount);
      return;
    case columnsNames.discount.value:
      handleDiscountUpdateLogic(sample_strategy_report, summary, discount);
      handleSampleCostUpdateLogic(sample_strategy_report, summary);
      return;

    default:
      return null;
  }
};

const normalFormatter = (cell, options) => {
  return cell.name;
};

const costFormatter = (cell) => {
  return cell ? `$${cell}` : "";
};

const handleRowSelection = (cell, row, handleSelectRow) => {
  return (
    <div className={styles.checkInputCnt} onClick={() => handleSelectRow(row)}>
      <input
        className={styles.select_checkbox}
        type="checkbox"
        checked={row[columnsNames.status.value]}
        // onChange={() => handleSelectRow(row)}
      />
    </div>
  );
};

const handleTextLengthValidation = ({
  row,
  newValue,
  column,
  validationError,
  setValidationError,
  maxLength = 50,
  isRequired = true,
}) => {
  if (!newValue && isRequired) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `${column.text} is required`,
      },
    });
    return {
      valid: false,
      message: `${column.text} is required`,
    };
  } else if (newValue.length > maxLength) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `${column.text} must be ${maxLength} characters or less`,
      },
    });
    return {
      valid: false,
      message: `${column.text} must be ${maxLength} characters or less`,
    };
  } else {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: false,
        message: "",
      },
    });
  }
  return true;
};

const handleRoomValidation = ({
  row,
  newValue,
  column,
  validationError,
  setValidationError,
  maxLength = 50,
  isRequired = true,
  data,
}) => {
  const roomObj = {
    id: row.room_name?.id,
    name: newValue,
  };
  const isDuplicate = data.find(
    (li) =>
      li.room_name?.id !== roomObj.id &&
      li.room_name.name.trim().toLowerCase() ===
        roomObj.name.trim().toLowerCase()
  );
  if (!newValue && isRequired) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `${column.text} is required`,
      },
    });
    return {
      valid: false,
      message: `${column.text} is required`,
    };
  } else if (newValue.length > maxLength) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `${column.text} must be ${maxLength} characters or less`,
      },
    });
    return {
      valid: false,
      message: `${column.text} must be ${maxLength} characters or less`,
    };
  } else if (isDuplicate) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `Room already exists in line number ${isDuplicate.row_number}`,
      },
    });
    return {
      valid: false,
      message: `Room already exists in line number ${isDuplicate.row_number}`,
    };
  } else {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: false,
        message: "",
      },
    });
  }
  return true;
};

const handleObservationValidation = ({
  row,
  newValue,
  column,
  validationError,
  setValidationError,
  maxLength = 50,
  isRequired = true,
  data,
}) => {
  const roomObj = {
    id: row.observation?.id,
    name: newValue,
  };
  const isDuplicate = data.find(
    (li) =>
      li.room_name?.id !== roomObj.id &&
      li.room_name.name.trim().toLowerCase() ===
        roomObj.name.trim().toLowerCase()
  );
  if (!newValue && isRequired) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `${column.text} is required`,
      },
    });
    return {
      valid: false,
      message: `${column.text} is required`,
    };
  } else if (newValue.length > maxLength) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `${column.text} must be ${maxLength} characters or less`,
      },
    });
    return {
      valid: false,
      message: `${column.text} must be ${maxLength} characters or less`,
    };
  } else if (isDuplicate) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `Room already exists in line number ${isDuplicate.row_number}`,
      },
    });
    return {
      valid: false,
      message: `Room already exists in line number ${isDuplicate.row_number}`,
    };
  } else {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: false,
        message: "",
      },
    });
  }
  return true;
};

const handlePriceValidation = (
  newValue,
  row,
  column,
  validationError,
  setValidationError
) => {
  const regex = /^\d{1,5}(\.\d{1,2})?$/; // Up to 5 digits before decimal and 2 digits after

  if (!newValue) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: "Cost is required",
      },
    });
    return {
      valid: false,
      message: "Cost is required",
    };
  } else if (isNaN(newValue) || !regex.test(newValue)) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message:
          "Cost should be numeric with up to 5 digits before decimal and 2 digits after",
      },
    });
    return {
      valid: false,
      message:
        "Cost should be numeric with up to 5 digits before decimal and 2 digits after",
    };
  } else if (newValue <= 0) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: "Cost should be greater than 0",
      },
    });
    return {
      valid: false,
      message: "Cost should be greater than 0",
    };
  } else {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: false,
        message: "",
      },
    });
  }
  return true;
};

const roomColumnDynamicStyle = (isDisplayHiddenColumns, error) => {
  let errorWidth = error ? "40" : "0";
  const normalWidth = isDisplayHiddenColumns ? "150px" : "200px";
  return `calc(${normalWidth} + ${errorWidth}px)`;
};

const handleAllCheck = (selectAllRows, handleAllSelectRow) => {
  return (
    <div className={styles.selectAllCheck}>
      <input
        className={styles.select_checkbox}
        style={{ marginTop: "0px !important" }}
        type="checkbox"
        checked={selectAllRows}
        onChange={(e) => handleAllSelectRow(e)}
      />
    </div>
  );
};

export const getColumnsOfReportTable = ({
  testListOptions = [],
  isDisplayHiddenColumns = true,
  handleHiddenColumn = () => {},
  discountOptions = [],
  sampleReasonOptions = [],
  handleFlagSelection = () => {},
  handleSelectRow = () => {},
  handleAllSelectRow = () => {},
  selectAllRows,
  validationError,
  setValidationError,
  data = [],
}) => {
  const columns = [
    {
      dataField: columnsNames.id.value,
      text: columnsNames.id.name,
      hidden: true,
      headerStyle: { width: "60px" },
      style: { width: "60px", borderRadius: "0px" },
    },
    {
      dataField: columnsNames.status.value,
      text: handleAllCheck(selectAllRows, handleAllSelectRow),
      editable: false,
      headerStyle: { width: "50px", padding: "0 17px" },
      style: {
        width: "50px",
        padding: "0 17px !important",
        borderRadius: "0px",
      },
      formatter: (cell, row) => handleRowSelection(cell, row, handleSelectRow),
    },
    {
      dataField: columnsNames.row_number.value,
      text: columnsNames.row_number.name,
      editable: false,
      headerStyle: { width: "75px" },
      style: { width: "75px", borderRadius: "0px" },
    },
    {
      dataField: columnsNames.room.value,
      text: columnsNames.room.name,
      editorClasses: "editor-class",
      headerStyle: {
        width: roomColumnDynamicStyle(
          isDisplayHiddenColumns,
          validationError[columnsNames.room.value]?.error
        ),
      },
      style: {
        width: roomColumnDynamicStyle(
          isDisplayHiddenColumns,
          validationError[columnsNames.room.value]?.error
        ),
      },

      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        return (
          <CustomInputField
            editorProps={editorProps}
            value={value}
            type="text"
            maxLength="50"
          />
        );
      },
      validator: (newValue, row, column) =>
        handleRoomValidation({
          row,
          newValue,
          column,
          validationError,
          setValidationError,
          data,
        }),
    },
    {
      dataField: columnsNames.observation.value,
      text: columnsNames.observation.name,
      editorClasses: "editor-class",
      headerStyle: {
        width: roomColumnDynamicStyle(
          isDisplayHiddenColumns,
          validationError[columnsNames.observation.value]?.error
        ),
      },
      style: {
        width: roomColumnDynamicStyle(
          isDisplayHiddenColumns,
          validationError[columnsNames.observation.value]?.error
        ),
      },

      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        return (
          <CustomInputField
            editorProps={editorProps}
            value={value}
            type="text"
            maxLength="50"
          />
        );
      },
      validator: (newValue, row, column) => {},
      // handleObservationValidation({
      //   row,
      //   newValue,
      //   column,
      //   validationError,
      //   setValidationError,
      //   data,
      // }),
    },
    {
      dataField: columnsNames.sample_type.value,
      text: columnsNames.sample_type.name,
      editorClasses: "editor-class",
      headerStyle: { width: isDisplayHiddenColumns ? "160px" : "200px" },
      style: { width: isDisplayHiddenColumns ? "160px" : "200px" },
      formatter: (cell) => normalFormatter(cell, testListOptions),
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) =>
        CustomTestDropDown(
          testListOptions,
          editorProps.onUpdate,
          editorProps,
          rowIndex,
          data
        ),
    },
    {
      dataField: columnsNames.sample_location.value,
      text: columnsNames.sample_location.name,
      editorClasses: "editor-class",
      headerStyle: { width: isDisplayHiddenColumns ? "130px" : "200px" },
      style: { width: isDisplayHiddenColumns ? "130px" : "200px" },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        return (
          <CustomInputField
            editorProps={editorProps}
            value={value}
            type="text"
            maxLength="50"
          />
        );
      },
      validator: (newValue, row, column) =>
        handleTextLengthValidation({
          row,
          newValue,
          column,
          validationError,
          setValidationError,
          isRequired: false,
        }),
    },
    {
      dataField: columnsNames.lab_code.value,
      text: columnsNames.lab_code.name,
      editorClasses: "editor-class",
      hidden: isDisplayHiddenColumns,
      headerStyle: { width: "120px" },
      style: { width: "120px" },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        return (
          <CustomInputField
            editorProps={editorProps}
            value={value}
            type="text"
            maxLength="50"
          />
        );
      },
      validator: (newValue, row, column) =>
        handleTextLengthValidation({
          row,
          newValue,
          column,
          validationError,
          setValidationError,
          isRequired: false,
        }),
    },
    {
      dataField: columnsNames.sample_cost.value,
      text: columnsNames.sample_cost.name,
      editorClasses: "editor-class",
      validator: (newValue, row, column) =>
        handlePriceValidation(
          newValue,
          row,
          column,
          validationError,
          setValidationError
        ),
      formatter: costFormatter,
      ...(isDisplayHiddenColumns && {
        headerFormatter: (column, colIndex) =>
          CustomHeader(column, colIndex, handleHiddenColumn),
      }),
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        return (
          <CustomInputField
            editorProps={editorProps}
            name="sample_cost"
            value={value}
            type="number"
            maxLength="7"
            min={0}
          />
        );
      },
      headerStyle: {
        width: validationError[columnsNames.sample_cost.value]?.error
          ? "240px"
          : "140px",
      },
      style: {
        width: validationError[columnsNames.sample_cost.value]?.error
          ? "240px"
          : "140px",
      },
    },
    {
      dataField: columnsNames.discount.value,
      text: columnsNames.discount.name,
      editorClasses: "editor-class",
      hidden: isDisplayHiddenColumns,
      formatter: (cell) => normalFormatter(cell, discountOptions),
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) =>
        CustomDiscountDropDown(
          discountOptions,
          editorProps.onUpdate,
          editorProps,
          rowIndex,
          data
        ),
      headerStyle: { width: "160px" },
      style: { width: "160px" },
    },
    {
      dataField: columnsNames.sample_reason.value,
      text: columnsNames.sample_reason.name,
      editorClasses: "editor-class",
      hidden: isDisplayHiddenColumns,
      ...(!isDisplayHiddenColumns && {
        formatter: (cell) => normalFormatter(cell, sampleReasonOptions),
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) =>
          CustomReasonDropDown(
            sampleReasonOptions,
            editorProps.onUpdate,
            editorProps,
            rowIndex,
            data
          ),
      }),
      headerStyle: { width: "180px" },
      style: { width: "180px" },
    },

    {
      dataField: columnsNames.client_approval.value,
      text: columnsNames.client_approval.name,
      editorClasses: "editor-class",
      editable: false,
      hidden: isDisplayHiddenColumns,
      ...(!isDisplayHiddenColumns && {
        headerFormatter: (column, colIndex) =>
          CustomHeader(column, colIndex, handleHiddenColumn),
        formatter: (cell, row) =>
          customCellForFlagFormatter(cell, row, handleFlagSelection),
      }),
      headerStyle: { width: "100px" },
      style: { width: "100px", borderRadius: "0px" },
    },
  ];
  return columns;
};

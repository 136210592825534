/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  faPen,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DollarInput from "main/components/DollarInput/DollarInput";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useHistory, useParams } from "react-router";
import {
  deleteTest,
  deleteTestSample,
  deleteTestSampleInfo,
  getTestById,
  updateTest,
} from "../../../services/TestService";
import CustomInput from "../../components/Custom/CustomInput";
import {
  deleteArrayIndex,
  getErrorMsg,
  showToast,
  updateArrayIndex,
  USD,
} from "../../shared/helpers";

import { SET_CONFIRMATION_MODAL } from "store/actions";

import { useDispatch, useSelector } from "react-redux";
import "./style.css";

export default function UpdateTest() {
  const [name, setName] = useState("");
  const [tests, setTests] = useState([]);
  const [sampleInfos, setSampleInfos] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const { testId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getTest(testId);
  }, [testId]);

  const handleDelTest = (disable) => {
    disable(true);
    deleteTest(testId)
      .then(() => {
        history.replace("/tools/tests");
        showToast("Test deleted succussfully", true);
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to delete test");
      })
      .finally(() => disable(false));
  };

  const getTest = (testId) => {
    setIsLoading(true);
    getTestById(testId)
      .then((res) => {
        setName(res.data.name);
        setSampleInfos(res.data.sample_info);
        setTests(
          res.data.test_samples.map((sample) => {
            sample.cost = USD(sample.cost).format();
            return sample;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdate = async () => {
    setIsSaving(true);
    const sample_info_update = [];
    const sample_info_update_ids = [];
    const test_samples_update = [];
    const test_costs_update = [];
    const test_samples_update_ids = [];

    const test_samples_add_new = [];
    const test_costs_add_new = [];
    const sample_info_add_new = [];

    tests.forEach((item) => {
      if (item.id) {
        test_samples_update_ids.push(item.id);
        test_samples_update.push(item.name);
        test_costs_update.push(item.cost.replace("$", "").replaceAll(",", ""));
      } else {
        test_samples_add_new.push(item.name);
        test_costs_add_new.push(item.cost.replace("$", "").replaceAll(",", ""));
      }
    });

    sampleInfos.forEach((item) => {
      if (item.id) {
        sample_info_update_ids.push(item.id);
        sample_info_update.push(item.name);
      } else {
        sample_info_add_new.push(item.name);
      }
    });

    const data = {
      name: name,

      sample_info_update,
      sample_info_update_ids,

      test_samples_update,
      test_costs_update,
      test_samples_update_ids,
      test_costs_update_ids: test_samples_update_ids,

      ...(test_samples_add_new.length > 0 && { test_samples_add_new }),
      ...(test_costs_add_new.length > 0 && { test_costs_add_new }),
      ...(sample_info_add_new.length > 0 && { sample_info_add_new }),
    };

    updateTest(testId, data)
      .then((res) => {
        setEditEnabled(false);
        setName(res.data.name);
        setSampleInfos(res.data.sample_info);
        setTests(res.data.test_samples);
      })
      .then(() => {
        showToast("Test updated successfully.", true);
      })
      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to create test.");
      })
      .finally(() => setIsSaving(false));
  };

  const asyncUseEffect = async (name, sampleInfos, tests) => {
    if (
      name &&
      sampleInfos.every((i) => i.name) &&
      tests.every((i) => i.name && i.cost)
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  };

  const handleDelete = (data) => {
    dispatch({
      type: SET_CONFIRMATION_MODAL,
      payload: {
        show: true,
        okText: "Delete",
        cancelText: "Cancel",
        title: "",
        message: "Are you sure you want to delete all selected properties?",
        onOk: handleDeleteTestSample(data),
      },
    });
  };

  const handleDeleteSample = (data) => {
    dispatch({
      type: SET_CONFIRMATION_MODAL,
      payload: {
        show: true,
        okText: "Delete",
        cancelText: "Cancel",
        title: "",
        message: "Are you sure you want to delete all selected properties?",
        onOk: handleDeleteSampleInfo(data),
      },
    });
  };

  const handleDeleteTestSample = (sampleId) => () => {
    setIsLoading(true);
    deleteTestSample(sampleId)
      .then(() => {
        showToast("Test sample deleted successfully.", true);
        getTest(testId);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(getErrorMsg(err) || "Unable to delete test sample.");
      });
  };

  const handleDeleteSampleInfo = (sampleInfoId) => () => {
    setIsLoading(true);
    deleteTestSampleInfo(sampleInfoId)
      .then(() => {
        showToast("Sample info deleted successfully.", true);
        getTest(testId);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(getErrorMsg(err) || "Unable to delete sample info.");
      });
  };

  const sortSampleInfos = (a, b) => {
    const normalize = (name) =>
      name.trim().toLowerCase().replace(/_/g, " ").replace(/\s+/g, "");

    const aName = normalize(a.name);
    const bName = normalize(b.name);

    if (aName === "labcode" && bName === "samplelocation") {
      return -1;
    }
    if (aName === "samplelocation" && bName === "labcode") {
      return 1;
    }
    return 0; // Keep the order of other items unchanged
  };

  useEffect(() => {
    asyncUseEffect(name, sampleInfos, tests);
  }, [name, sampleInfos, tests]);

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div className="main-content-area">
            <div className="d-flex align-items-center">
              {editEnabled ? (
                <div className="d-flex ml-auto">
                  <button
                    className={`btn btn-primary ml-auto`}
                    id="add_test"
                    onClick={handleUpdate}
                    disabled={isSaving || disableSave}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-light ml-2 low-priority"
                    onClick={() => {
                      setEditEnabled(false);
                      getTest(testId);
                    }}
                    disabled={isSaving}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="d-flex ml-auto">
                  <button
                    className="btn btn-primary delbtn"
                    onClick={() => setShowDelModal(true)}
                    id="delete_test"
                  >
                    Delete Test
                    <FontAwesomeIcon icon={faTrash} color="#fff" />
                  </button>
                  <button
                    className={`btn btn-primary`}
                    id="add_test"
                    onClick={() => setEditEnabled(true)}
                  >
                    Edit Test
                    <FontAwesomeIcon icon={faPen} color="#fff" />
                  </button>
                </div>
              )}
            </div>

            <div className="row p-3">
              <div className="col-md-6 col-sm-12">
                <h4>What Kind Of Test Is This?</h4>
                <div className="form-group">
                  <CustomInput
                    label="Test Type"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    inputProps={{
                      maxLength: "50",
                      disabled: !editEnabled,
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between pr-3 pl-3">
              <h4>What Info Needs To Be Sampled?</h4>
              {editEnabled && (
                <button
                  className="btn btn-primary mr-1"
                  onClick={() => {
                    setSampleInfos([{ name: "" }, ...sampleInfos]);
                  }}
                  disabled={sampleInfos.length === 5}
                >
                  Add Sample Info <FontAwesomeIcon icon={faPlus} />
                </button>
              )}
            </div>
            <div className="pr-3 pl-3">
              <div className="row">
                {[...sampleInfos]
                  .sort((a, b) => sortSampleInfos(a, b))
                  .map((item, i) => {
                    const trimmedName = item.name;
                    const isLabcode = trimmedName === "Lab Code";
                    const isSampleLocation = trimmedName === "Sample Location";
                    const isDisabled =
                      !editEnabled || isLabcode || isSampleLocation;

                    return (
                      <div
                        className="col-md-5 col-sm-12"
                        key={`sample-info-${i}`}
                      >
                        <div className="form-group d-flex flex-row align-items-center">
                          <CustomInput
                            label="Sample Info"
                            value={item.name}
                            onChange={(e) => {
                              setSampleInfos((state) =>
                                updateArrayIndex(i, state, {
                                  ...item,
                                  name: e.target.value,
                                })
                              );
                            }}
                            inputProps={{
                              maxLength: "50",
                              disabled: isDisabled,
                            }}
                            parentClassName={"webkit-fill-available"}
                          />
                          {!editEnabled && !(isLabcode || isSampleLocation) && (
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="text-light50 ml-3 cursor-pointer"
                              onClick={() => handleDeleteSample(item.id)}
                            />
                          )}
                          {editEnabled && !item.id && (
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="text-light50 ml-3 cursor-pointer"
                              onClick={() =>
                                sampleInfos.length !== 1 &&
                                setSampleInfos((state) =>
                                  deleteArrayIndex(i, state)
                                )
                              }
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between pr-3 pl-3 pt-3">
              <h4>What Are You Testing For?</h4>
              {editEnabled && (
                <button
                  className="btn btn-primary mr-1"
                  onClick={() => {
                    setTests([...tests, { name: "", cost: "" }]);
                  }}
                  disabled={tests.length === 15}
                >
                  Add Sample Type <FontAwesomeIcon icon={faPlus} />
                </button>
              )}
            </div>
            {tests.map((_, i) => (
              <div className="row pr-3 pl-3" key={`test-${i}`}>
                <div className="form-group col-md-4">
                  <CustomInput
                    label="Sample Type"
                    value={tests[i].name}
                    onChange={(e) =>
                      setTests((state) =>
                        updateArrayIndex(i, state, {
                          ...tests[i],
                          name: e.target.value,
                          cost: tests[i].cost,
                        })
                      )
                    }
                    inputProps={{
                      maxLength: "50",
                      disabled: !editEnabled,
                    }}
                  />
                </div>
                <div className="form-group col-md-4">
                  <div className="form-group d-flex flex-row align-items-center">
                    <DollarInput
                      label="Customer Cost"
                      type="text"
                      className="form-control"
                      value={tests[i].cost}
                      onChange={(e) => {
                        setTests((state) =>
                          updateArrayIndex(i, state, {
                            ...tests[i],
                            name: tests[i].name,
                            cost: e.target.value,
                          })
                        );
                      }}
                      disabled={!editEnabled}
                    />
                    {!editEnabled && tests[i].id && tests.length !== 1 && (
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-light50 ml-3 cursor-pointer"
                        // onClick={handleDeleteTestSample(tests[i].id)}
                        onClick={() => {
                          handleDelete(tests[i].id);
                        }}
                      />
                    )}
                    {editEnabled && !tests[i].id && (
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-light50 ml-3 cursor-pointer"
                        onClick={() =>
                          tests.length !== 1 &&
                          setTests((state) => deleteArrayIndex(i, state))
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </ScrollBar>
      <Delete
        show={showDelModal}
        onHide={() => setShowDelModal(false)}
        msg={"“Are you sure you want to delete this test?”"}
        delfunc={handleDelTest}
      />
    </div>
  );
}

export function Delete(props) {
  const { msg, delfunc } = props;

  const [dis, setdis] = useState(false);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="dialogheader">
            <div className="d-flex">
              <img
                className="iconstyle"
                src={
                  require("../../../assets/icons/attention_light20@3x.png")
                    .default
                }
                alt="icon"
              />
              <h5 style={{ fontWeight: "bolder" }}>Warning</h5>
            </div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/x_light50@3x.png").default}
              alt="icon"
              onClick={!dis && props.onHide}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          <span>{msg}</span>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          disabled={dis}
          variant="light"
          onClick={() => delfunc(setdis)}
          style={{
            color: "#962424",
            background: "transparent",
            border: "none",
          }}
          id="delete"
        >
          Delete
        </Button>
        <Button
          disabled={dis}
          style={{ marginRight: 25 }}
          onClick={props.onHide}
          id="dont_delete"
        >
          Don't Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

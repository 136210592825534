import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

export default function CustomInput({
  inputProps,
  label,
  value,
  onChange,
  parentClassName,
  password,
  disabled,
}) {
  const [viewPass, setViewPass] = useState(false);

  return (
    <div className={`float-label ${parentClassName || ""}`}>
      <label className={value ? "Active input-field" : "input-field"}>
        {label}
      </label>
      <input
        className="form-control"
        style={
          value === "Sample Location" || value === "Lab Code"
            ? { width: "96%" }
            : {}
        }
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...inputProps}
        {...(password ? { type: viewPass ? "text" : "password" } : null)}
      />
      {password && (
        <FontAwesomeIcon
          className="pass-eye"
          icon={viewPass ? faEyeSlash : faEye}
          onClick={() => setViewPass((s) => !s)}
        />
      )}
    </div>
  );
}

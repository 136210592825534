/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { authRoles } from "../../../../navigation/route-config";
import { createInspector } from "../../../../services/bu-admin-service";
import { uploadToS3 } from "../../../../services/s3";
import {
  getErrorMsg,
  permissions,
  roleNames,
  showToast,
  updateArrayIndex,
} from "../../../shared/helpers";
import { regexes } from "../../../shared/regexes";
import { fileSizeLimits, folders } from "../../../shared/validations";
import "../style.css";
import { AsYouType } from "libphonenumber-js";
import ScrollBar from "react-perfect-scrollbar";
import CustomInput from "../../../components/Custom/CustomInput";
import Toggle from "../../../components/Toggle/Toggle";
import InfoIcon from "main/components/InfoIcon/InfoIcon";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";

const AddUser = () => {
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [file_e, setFile_e] = useState("");
  const user = useSelector((state) => state.user.data);
  const [type, setType] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [type_e, setType_e] = useState("");
  const [first_name_e, setFirst_name_e] = useState("");
  const [last_name_e, setLast_name_e] = useState("");
  const [phone_number_e, setPhone_number_e] = useState("");
  const [email_e, setEmail_e] = useState("");
  const [confirmPassword_e, setConfirmPassword_e] = useState("");
  const [permissions_e, setPermissions_e] = useState("");

  const [perm, setPerm] = useState([]);

  const [disableSave, setDisableSave] = useState(false);

  const clearForm = () => {
    setType("");
    setFirst_name("");
    setLast_name("");
    setPhone_number("");
    setEmail("");
    setBio("");
    setPassword("");
    setConfirmPassword("");
    setFile(null);
    setPreview(null);
    setPerm([]);
  };

  const handleSave = () => {
    setDisableSave(true);
    if (validateForm() && file_e === "") {
      console.log("file", file);

      if (file) {
        uploadToS3(file, folders.profile)
          .then((res) => {
            console.log(res);
            addUserFunc(res.url);
          })
          .catch((err) => {
            console.log(err);
            showToast("Error while uploading image");
            setDisableSave(false);
          });
      } else {
        addUserFunc();
      }
    } else {
      setDisableSave(false);
    }
  };

  // const addUserFunc = (avatar_url = "") => {
  //   createInspector({
  //     type: type === authRoles.buLeadInspector ? "bu_inspector" : type,
  //     first_name,
  //     last_name,
  //     phone_number,
  //     email,
  //     bio,
  //     is_lead_inspector: type === authRoles.buLeadInspector ? true : false,
  //     ...(avatar_url ? { avatar_url } : null),
  //     ...(type === authRoles.buSubAdmin
  //       ? {
  //           permissions: perm.filter((i) => i),
  //         }
  //       : { password, password_confirmation: password }),
  //   })
  //     .then(() => {
  //       clearForm();
  //       showToast(
  //         type === authRoles.buSubAdmin
  //           ? "Sub-Admin created Successfully."
  //           : type === authRoles.buLeadInspector
  //           ? "Lead Inspector created Successfully."
  //           : "Inspector created Successfully.",
  //         true
  //       );
  //     })
  //     .catch((err) => {
  //       showToast(getErrorMsg(err));
  //     })
  //     .finally(() => {
  //       setDisableSave(false);
  //     });
  // };

  const addUserFunc = (avatar_url = "") => {
    const isBuLeadInspector = type === authRoles.buLeadInspector;
    const isBuSubAdmin = type === authRoles.buSubAdmin;

    const userPayload = {
      type: isBuLeadInspector ? "bu_inspector" : type,
      first_name,
      last_name,
      phone_number,
      email,
      bio,
      // is_lead_inspector: isBuLeadInspector,
      ...(avatar_url && { avatar_url }),
      ...(isBuSubAdmin
        ? { permissions: perm.filter(Boolean) }
        : { password, password_confirmation: password }),
    };

    createInspector(userPayload)
      .then(() => {
        clearForm();
        const successMessage = isBuSubAdmin
          ? "Sub-Admin created Successfully."
          : isBuLeadInspector
          ? "Lead Inspector created Successfully."
          : "Inspector created Successfully.";
        showToast(successMessage, true);
      })
      .catch((err) => {
        showToast(getErrorMsg(err));
      })
      .finally(() => {
        setDisableSave(false);
      });
  };

  const validateForm = () => {
    let isValid = true;
    if (type === "") {
      setType_e("Role cannot be empty");
      isValid = false;
    } else {
      setType_e("");
    }
    if (first_name === "") {
      setFirst_name_e("First name cannot be empty");
      isValid = false;
    } else {
      setFirst_name_e("");
    }
    if (last_name === "") {
      setLast_name_e("Last name cannot be empty");
      isValid = false;
    } else {
      setLast_name_e("");
    }
    if (email === "") {
      setEmail_e("Email cannot be empty");
      isValid = false;
    } else if (email !== "" && !regexes.email.test(email)) {
      setEmail_e("Please enter a valid email address");
      isValid = false;
    } else {
      setEmail_e("");
    }
    if (phone_number === "") {
      setPhone_number_e("Phone cannot be empty");
      isValid = false;
    } else if (phone_number !== "" && !regexes.phone.test(phone_number)) {
      setPhone_number_e("Please enter a valid phone number.");
      isValid = false;
    } else {
      setPhone_number_e("");
    }

    if (perm.length > 0 && !perm.find((i) => i !== null && i !== undefined)) {
      setPermissions_e("At least one permission is required");
      isValid = false;
    } else {
      setPermissions_e("");
    }

    if (type === authRoles.buInspector) {
      if (password === "") {
        setConfirmPassword_e("Password cannot be empty");
        isValid = false;
      } else if (password !== confirmPassword) {
        setConfirmPassword_e("Passwords donot match");
        isValid = false;
      } else if (password.length < 8) {
        setConfirmPassword_e("Password must be from 8 to 15 characters");
        isValid = false;
      } else if (password !== "" && !regexes.password.test(password)) {
        setConfirmPassword_e(
          "Password should contain one upper case letter, one lowercase letter, one number and one special character"
        );
        isValid = false;
      } else {
        setConfirmPassword_e("");
      }
    } else {
      setConfirmPassword_e("");
    }
    return isValid;
  };

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      if (file.size > fileSizeLimits.avatarBuInspector) {
        setFile_e("File size cannot be larger than 2MB");
      } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFile_e("Image format should be JPG or PNG");
      } else {
        setFile_e("");
        setPreview(URL.createObjectURL(file));
        setFile(file);
      }
    }
  };

  useEffect(() => {
    if (type !== "bu_sub_admin") {
      setPerm([]);
    }
  }, [type]);

  useLayoutEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        <div className="main-content-area">
          <div className="d-flex align-items-center">
            <button
              className={`btn btn-primary ml-auto ${
                disableSave ? "disabled-bg-color" : ""
              }`}
              onClick={() => handleSave()}
              disabled={disableSave}
            >
              Save
            </button>
          </div>
          <form>
            <h4>
              <b>Personal Details</b>
            </h4>
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <CustomInput
                        label="First Name"
                        value={first_name}
                        onChange={(e) => setFirst_name(e.target.value)}
                        inputProps={{ maxLength: "50" }}
                      />
                      <small className="text-danger ml-1">{first_name_e}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <CustomInput
                        label="Last Name"
                        value={last_name}
                        onChange={(e) => setLast_name(e.target.value)}
                        inputProps={{ maxLength: "50" }}
                      />
                      <small className="text-danger ml-1">{last_name_e}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <CustomInput
                        label="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        inputProps={{ maxLength: "50", autoComplete: "off" }}
                      />
                      <small className="text-danger ml-1">{email_e}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <CustomInput
                        label="Phone Number"
                        value={new AsYouType("US").input(phone_number)}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(regexes.phoneInput, "");
                          setPhone_number(value);
                        }}
                        inputProps={{ maxLength: "16" }}
                      />
                      <small className="text-danger ml-1">
                        {phone_number_e}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={type}
                        onChange={(e) => setType(e.currentTarget.value)}
                      >
                        <option value="" defaultValue disabled="disabled">
                          Role
                        </option>

                        <option value={authRoles.buInspector}>Inspector</option>
                        <option value={authRoles.buSubAdmin}>Sub-Admin</option>
                      </select>
                      <small className="text-danger ml-1">{type_e}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="upload-avatar">
                  <div className="image-area">
                    {!preview ? (
                      <span
                        className="upload-icon"
                        style={
                          preview
                            ? { backgroundColor: "transparent" }
                            : { backgroundColor: "#fff" }
                        }
                      >
                        <FontAwesomeIcon icon={faCamera} />
                      </span>
                    ) : null}
                    <img
                      width="120"
                      className="avatar-lg"
                      src={preview}
                      alt=""
                    />
                    <input
                      type="file"
                      className="upload-image"
                      accept="image/jpeg,image/png"
                      onChange={(e) => {
                        fileChangedHandler(e);
                      }}
                    />
                  </div>
                  <p>Upload Photo (Optional)</p>
                  <small className="text-danger ml-1">{file_e}</small>
                </div>
              </div>
            </div>

            <hr className="mt-0 mb-4" />

            {type === authRoles.buSubAdmin ? (
              <div>
                <h4>
                  <b>Permissions</b>
                </h4>
                <div className="ml-3">
                  {permissions.map((item, i) => (
                    <div
                      className="d-flex flex-row align-items-center my-3"
                      key={`perm-${i}`}
                    >
                      <Toggle
                        on={perm[i] && true}
                        onChange={(val) => {
                          setPerm(
                            updateArrayIndex(i, perm, val ? item.value : null)
                          );
                        }}
                      />
                      <div>
                        <h4 className="mb-0">
                          <b>{item.name}</b>
                        </h4>
                        <span>{item.description}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <small className="text-danger ml-1">{permissions_e}</small>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-10">
                  <h4>
                    <b>Inspector Bio</b>
                  </h4>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          rows="6"
                          maxLength="1000"
                          placeholder="Optional"
                          value={bio}
                          onChange={(e) => setBio(e.target.value)}
                        ></textarea>
                        <small className="text-danger ml-1"></small>
                        <small style={{ float: "right" }}>
                          {bio?.length}/1000
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <hr className="mb-4" />

            {type === authRoles.buInspector ||
            type === authRoles.buLeadInspector ? (
              <div className="row">
                <div className="col-md-10">
                  <h4>
                    <b>Enter {roleNames[type]} New Password</b>
                    <InfoIcon
                      dataTip="Password must contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character."
                      style={{ color: "#2f481e", marginLeft: "0.5rem" }}
                    />
                  </h4>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Enter Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          inputProps={{
                            maxLength: "15",
                            autoComplete: "new-password",
                          }}
                          password
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <h4>
                    <b>Re-enter {roleNames[type]} New Password</b>
                  </h4>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomInput
                          label="Enter Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          inputProps={{
                            maxLength: "15",
                            autoComplete: "new-password",
                          }}
                          password
                        />
                        <small className="text-danger ml-1 d-flex">
                          {confirmPassword_e}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </ScrollBar>
    </div>
  );
};

export default AddUser;

import ForgetPassword from "../main/pages/Auth/ForgotPassword";
import Login from "../main/pages/Auth/Login";
import ResetPassword from "../main/pages/Auth/ResetPassword";
import Settings from "../main/pages/Settings";
import Rooms from "../main/pages/Rooms";
import Comments from "../main/pages/Comments";
import Tests from "../main/pages/Tests";
import AddTest from "../main/pages/Tests/addTest";
import SuperAdmin from "../main/pages/SuperAdmin";
import AddBusinessUnit from "../main/pages/SuperAdmin/AdminTabs/BusinessUnit/addBusinessUnit";
import TermsAndPolicy from "../main/pages/TermsAndPolicy";
import Customer from "../main/pages/Customers";
import AddCustomer from "../main/pages/Customers/AddCustomer";
import Property from "../main/pages/Properties";
import AddProperty from "../main/pages/Properties/AddProperty";
import Inspection, { AddInspection } from "../main/pages/Inspection";
import Subscription from "../main/pages/InitialPayment/Subscription";
import Profile from "../main/pages/InitialPayment/CompanyProfile";
import Labs, { AddLabs } from "../main/pages/Labs";
import Protocols from "../main/pages/Protocols";
import ReportTemplates from "../main/pages/ReportTemplates";
import ExtraFeeStructure from "../main/pages/ExtraFeeStructure";
import ServerDownPage from "../main/pages/ServerDownPage/ServerDownPage";
import InspectionDetails from "../main/pages/Inspection/InspectionDetails";
import BusinessUnitInfo from "../main/pages/SuperAdmin/AdminTabs/BusinessUnit/BusinessUnitInfo";
import EditCustomer from "../main/pages/Customers/EditCustomer";
import UpdateTest from "../main/pages/Tests/UpdateTest";
import EditProperty from "../main/pages/Properties/EditProperty";
import AddUser from "../main/pages/Settings/Users/AddUser";
import EditInspector from "../main/pages/Settings/Users/EditInspector";
import EditSubAdmin from "../main/pages/Settings/Users/EditSubAdmin";
import InvestigationReport from "../main/pages/InvestigationReport";
import DownloadPDF from "../main/shared/DownloadPDF";
import EditSubAdminProfile from "../main/pages/Settings/Users/EditSubAdminProfile";
import LogoutPage from "main/components/LogoutPage/LogoutPage";
import ImageView from "main/pages/ImagePreview";
import SampleStrategyReport from "main/pages/SampleStrategy/SampleStrategyReport";

export const authRoles = {
  superAdmin: "super_admin",
  buAdmin: "bu_admin",
  buSubAdmin: "bu_sub_admin",
  buLeadInspector: "bu_lead_inspector",
  buInspector: "bu_inspector",
  buCustomer: "bu_customer",
  ultraAdmin: "ultra_admin",
};

const routes = [
  {
    name: "Login",
    path: "/login",
    exact: true,
    component: Login,
    isProtected: false,
    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Forget-Password",
    path: "/forget-password",
    component: ForgetPassword,
    exact: true,
    isProtected: false,
    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Reset-Password",
    path: "/reset-password/:id",
    component: ResetPassword,
    exact: true,
    isProtected: false,
    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Terms of Use",
    path: "/terms-of-use",
    component: TermsAndPolicy,
    exact: true,
    isProtected: "both", //for routes that will be available with and without authentication
    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Privacy Policy",
    path: "/privacy-policy",
    component: TermsAndPolicy,
    exact: true,
    isProtected: "both",
    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    icon: "lock_light20@3x.png",
    name: "Add Business Unit",
    path: "/business-unit/new",
    component: AddBusinessUnit,
    exact: true,
    isProtected: true,
    roles: [authRoles.superAdmin, authRoles.ultraAdmin],
    showGoBack: true,
  },
  {
    icon: "lock_light20@3x.png",
    name: "Business Unit",
    path: "/business-unit/:id",
    exact: true,
    component: BusinessUnitInfo,
    isProtected: true,
    roles: [authRoles.superAdmin, authRoles.ultraAdmin],
    showGoBack: true,
  },
  {
    icon: "toolkit_light20@3x.png",
    name: "Add Test",
    exact: true,
    path: "/tools/tests/new",
    component: AddTest,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.buSubAdmin, authRoles.ultraAdmin],
    showGoBack: true,
  },
  {
    icon: "toolkit_light20@3x.png",
    name: "Add Lab",
    exact: true,
    path: "/tools/labs/new",
    component: AddLabs,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.buSubAdmin, authRoles.ultraAdmin],
    showGoBack: true,
  },
  {
    icon: "toolkit_light20@3x.png",
    name: "Update Lab",
    exact: true,
    path: "/tools/labs/update/:labid",
    component: AddLabs,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.buSubAdmin, authRoles.ultraAdmin],
    showGoBack: true,
  },
  {
    icon: "customer_light20@3x.png",
    name: "Add Customers",
    path: "/customers/new",
    exact: true,
    component: AddCustomer,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showGoBack: true,
  },
  {
    icon: "home_light20@3x.png",
    name: "Add Property",
    path: "/properties/new",
    exact: true,
    component: AddProperty,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showGoBack: true,
  },
  {
    icon: "lock_light20@3x.png",
    name: "Step 2 - Subscription",
    exact: true,
    path: "/subscription",
    component: Subscription,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.ultraAdmin],
  },
  {
    icon: "lock_light20@3x.png",
    name: "Subscription Failed",
    exact: true,
    path: "/subscription/:failed",
    component: Subscription,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.ultraAdmin],
  },
  {
    icon: "lock_light20@3x.png",
    name: "Step 1 - Company Profile",
    exact: true,
    path: "/profile",
    component: Profile,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.ultraAdmin],
  },
  {
    icon: "toolkit_light20@3x.png",
    name: "Tools",
    exact: true,
    path: "/tools",
    component: Tests,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.buSubAdmin, authRoles.ultraAdmin],
    hasChildren: true,
    showInSideNav: true,
    childrens: [
      {
        name: "Tests",
        to: "/tests",
        component: Tests,
        showSearch: true,
      },
      {
        name: "Rooms",
        to: "/rooms",
        component: Rooms,
        showSearch: true,
      },
      {
        name: "Protocols",
        to: "/protocols",
        component: Protocols,
        showSearch: true,
      },
      {
        name: "Comments",
        to: "/comments",
        component: Comments,
        showSearch: true,
      },
      {
        name: "Labs",
        to: "/labs",
        component: Labs,
        showSearch: true,
      },
      {
        name: "Report Templates",
        to: "/report-templates",
        component: ReportTemplates,
        showSearch: true,
      },
      {
        name: "Extra Fee Structure",
        to: "/extra-fee-structure",
        component: ExtraFeeStructure,
      },
    ],
  },
  {
    icon: "customer_light20@3x.png",
    name: "Customers",
    path: "/customers",
    exact: true,
    component: Customer,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showInSideNav: true,
    showSearch: true,
  },
  {
    icon: "home_light20@3x.png",
    name: "Properties",
    path: "/properties",
    exact: true,
    component: Property,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showInSideNav: true,
    showSearch: true,
  },
  {
    icon: "list_light20@3x.png",
    name: "Inspections",
    path: "/inspections",
    exact: true,
    component: Inspection,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showInSideNav: true,
    showSearch: true,
  },
  {
    icon: "list_light20@3x.png",
    name: "Add Inspection",
    path: "/inspections/new",
    exact: true,
    component: AddInspection,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showGoBack: true,
  },
  {
    icon: "list_light20@3x.png",
    name: "Inspection",
    path: "/inspections/:insp_id",
    exact: true,
    component: InspectionDetails,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showGoBack: true,
  },
  {
    icon: "list_light20@3x.png",
    //also reffered as Investigation Report
    name: "Inspection Report",
    path: "/inspections/:insp_id/generate-report",
    exact: true,
    component: InvestigationReport,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showGoBack: true,
  },
  {
    icon: "list_light20@3x.png",
    name: "Sample Strategy Report",
    path: "/inspection/:insp_id/sample-strategy-report",
    exact: true,
    component: SampleStrategyReport,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showGoBack: true,
  },
  {
    icon: "gear_light20@3x.png",
    name: "Settings",
    path: "/settings",
    exact: true,
    component: Settings,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.buSubAdmin, authRoles.ultraAdmin],
    showInSideNav: true,
  },
  {
    icon: "lock_light20@3x.png",
    name: "Add User",
    path: "/settings/user/new",
    exact: true,
    component: AddUser,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.buSubAdmin, authRoles.ultraAdmin],
    showGoBack: true,
  },
  {
    icon: "lock_light20@3x.png",
    name: "Server Down",
    path: "/maintainance",
    component: ServerDownPage,
    isProtected: "both",
    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    icon: "list_light20@3x.png",
    name: "Add Test",
    path: "/tools/tests/:testId",
    exact: true,
    component: UpdateTest,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.buSubAdmin, authRoles.ultraAdmin],
    showGoBack: true,
  },
  {
    icon: "customer_light20@3x.png",
    name: "Edit Customer",
    path: "/customers/:customerId",
    exact: true,
    component: EditCustomer,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showGoBack: true,
  },
  {
    icon: "home_light20@3x.png",
    name: "Add Property",
    path: "/properties/new/:customerId/:customerName",
    exact: true,
    component: AddProperty,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showGoBack: true,
  },
  {
    icon: "home_light20@3x.png",
    name: "Edit Property",
    path: "/properties/:propertyId",
    exact: true,
    component: EditProperty,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showGoBack: true,
  },
  {
    icon: "list_light20@3x.png",
    name: "Add Inspection",
    path: "/inspections/new/:customerId/:customerName/:propertyId/:propertyAdd",
    exact: true,
    component: AddInspection,
    isProtected: true,
    roles: [
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
      authRoles.buInspector,
    ],
    showGoBack: true,
  },
  {
    icon: "list_light20@3x.png",
    name: "Edit User",
    path: "/settings/inspector/:userId",
    exact: true,
    component: EditInspector,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.buSubAdmin, authRoles.ultraAdmin],
    showGoBack: true,
  },
  {
    icon: "list_light20@3x.png",
    name: "Edit User",
    path: "/settings/subadmin/:userId",
    exact: true,
    component: EditSubAdmin,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.buSubAdmin, authRoles.ultraAdmin],
    showGoBack: true,
  },
  {
    icon: "list_light20@3x.png",
    name: "Edit Profile",
    path: "/subadmin-profile",
    exact: true,
    component: EditSubAdminProfile,
    isProtected: true,
    roles: [authRoles.buSubAdmin],
    showGoBack: true,
  },
  {
    icon: "list_light20@3x.png",
    name: "Logout Page",
    path: "/subscription-expired",
    exact: true,
    component: LogoutPage,
    isProtected: true,
    roles: [authRoles.buSubAdmin, authRoles.buInspector],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    icon: "lock_light20@3x.png",
    name: "Super Admin",
    exact: true,
    path: "/",
    component: SuperAdmin,
    isProtected: true,
    roles: [authRoles.superAdmin, authRoles.ultraAdmin],
    showInSideNav: true,
  },
  {
    icon: "lock_light20@3x.png",
    name: "Only Report",
    exact: true,
    path: "/only-report",
    component: DownloadPDF,
    isProtected: true,
    roles: [authRoles.buAdmin, authRoles.ultraAdmin, authRoles.buInspector],
    showInSideNav: false,
    hideNavigation: true,
  },
  {
    name: "Preview Image",
    path: "/image",
    component: ImageView,
    // exact: true,
    isProtected: "both", //for routes that will be available with and without authentication
    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
];

export default routes;
